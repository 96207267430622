<template>
    <div class ="getInput" @click="getFocus">
        <el-input v-if="this.type !='lx' && visible" v-model="text" @change="onChange" @blur="blur" placeholder="Please input" />
        <el-select v-if="(this.type =='lx' && visible)" v-model="text" @change="getChange" class="m-2" placeholder="微信/支付宝" size="large">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select>
        <span v-if="!visible" @click="getFocus">{{text}}</span>
    </div>
</template>
<script>
export default{
    props:['type'],
    data() {
        return {
            text:'',
            visible:false,
            options :[
                    {
                        value: 'Option1',
                        label: '微信',
                    },
                    {
                        value: 'Option2',
                        label: '支付宝',
                    },
                    ]
        }
    },
    methods:{
        onChange(val) {
            this.$nextTick(()=> {
                this.text = val;
                this.visible = false; 
            })
            this.$emit('monitor',this.text,this.type)
        },
        getChange(val) { 

            this.text = val;
            this.visible = false;
        },
        blur(e) {
            // if(this.type == 'lx') {
            //     this.$nextTick(()=> {
            //     this.visible = false;
            // })
            // }
            this.visible=false
        },
        getFocus() {
            this.visible=true
        }
    }
}
</script>
<style lang="scss" scoped>
    
    .getInput {
        width: 100%;
        height: 100%;
        :deep(.el-select) {
            margin-bottom:0 ;
        }
    }
</style>