<template>
    <div class="container-box">
        <div class="top-wrap">
            <div class="formItem">
                <p class="title" style="margin-bottom: 0.1rem;"><b>我的信息</b></p>
                <div class="itemWrap">
                    <div class="listItem">
                        <div class="item iTitle">报名总人数</div>
                        <div class="item"> <el-input v-model="formTable.t_rm" placeholder="请输入" /></div>
                        <!-- <div class="item">
							 <el-input v-model="text" placeholder="请输入" />
						</div> -->
                        <div class="item iTitle">报名总组数</div>
                        <div class="item"><el-input v-model="formTable.t_zm" placeholder="请输入" /></div>
                    </div>
                    <div class="listItem">
                        <div class="item iTitle">竞赛单人费用</div>
                        <div class="item"><el-input v-model="formTable.t_dm" placeholder="请输入" /></div>
                        <div class="item iTitle">竞赛总费用</div>
                        <div class="item"><el-input v-model="formTable.t_money" placeholder="请输入" /></div>
                    </div>
                    <div class="listItem">
                        <div class="item iTitle">发票类型</div>
                        <div class="item"> 
                        <el-select v-model="formTable.t_invoice" @change="getChange" class="m-2" placeholder="请选择" size="large">
                        <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        />
                    </el-select></div>
                        <div class="item iTitle">发票抬头</div>
                        <div class="item"><el-input v-model="formTable.t_invoice_title" placeholder="请输入" /></div>
                    </div>
                    <div class="listItem">
                        <div class="item iTitle">税号</div>
                        <div class="item"><el-input v-model="formTable.tax_num" placeholder="请输入" /></div>
                        <div class="item iTitle">发票备注</div>
                        <div class="item"><el-input v-model="formTable.mome" placeholder="请输入" /></div>
                    </div>
                    <div class="listItem">
                        <div class="item iTitle">纸质发票/电子发票</div>
                        <div class="item">
                            <el-select v-model="formTable.ele_invoice" @change="getChange" class="m-2" placeholder="请选择" size="large">
                                <el-option
                                v-for="item in options1"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                />
                            </el-select>
                        </div>
                        <div class="item iTitle">电子发票接收邮箱</div>
                        <div class="item"><el-input v-model="formTable.ele_mail" placeholder="请输入" /></div>
                    </div>
                    <div class="lastItem">
                        <div class="item iTitle">纸质发票邮寄地址</div>
                        <div class="item"><el-input v-model="formTable.address" placeholder="请输入" /></div>
                        <!-- <div class="item iTitle"></div>
                        <div class="item"></div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="top-wrap">
            <div class="formItem">
                <span class="title" style="margin-bottom: 0.1rem;"><b>证书邮寄信息</b></span>
                <div class="itemWrap">
                    <div class="listItem">
                        <div class="item iTitle">省赛获奖人数</div>
                        <div class="item"> <el-input v-model="formTable.t_rm" placeholder="请输入" /></div>
                        <!-- <div class="item">
							 <el-input v-model="text" placeholder="请输入" />
						</div> -->
                        <div class="item iTitle">国赛获奖人数</div>
                        <div class="item"><el-input v-model="formTable.t_zm" placeholder="请输入" /></div>
                    </div>
                    <div class="listItem">
                        <div class="item iTitle">总获奖人数</div>
                        <div class="item"><el-input v-model="formTable.t_dm" placeholder="请输入" /></div>
                        <div class="item iTitle">是否需要邮寄证书</div>
                        <div class="item"><el-input v-model="formTable.t_money" placeholder="请输入" /></div>
                    </div>
                    <div class="lastItem">
                        <div class="item iTitle">收件详细地址</div>
                        <div class="item"><el-input v-model="formTable.address" placeholder="请输入" /></div>
                        <!-- <div class="item iTitle"></div>
                        <div class="item"></div> -->
                    </div>
                </div>
                
            </div>
            <div class="buttom-btn">
                <el-button class="btn" @click="save">保存证书邮寄信息</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import Input from './textInput.vue';
export default {
    components:{
            Input,
        },
    data() {
        return {
            formTable:{
                t_rm:'',
                t_zm:'',
                t_dm:'',
                t_money:'',
                t_invoice:'',
                t_invoice_title:'',
                tax_num:'',
                mome:'',
                ele_invoice:'',
                ele_mail:'',
                address:''
            },
            options :[
                    {
                        value: '不开发票',
                        label: '不开发票',
                    },
                    {
                        value: '现代服务*报名费',
                        label: '现代服务*报名费',
                    },
                    {
                        value: '现代服务*技术服务费',
                        label: '现代服务*技术服务费',
                    },
                    {
                        value: '会展服务*会务费',
                        label: '会展服务*会务费',
                    },
                    {
                        value: '会展服务*会议费',
                        label: '会展服务*会议费',
                    },
                    ],
            options1:[
                    {
                        value: '电子发票',
                        label: '电子发票',
                    },
                    {
                        value: '纸质发票',
                        label: '纸质发票',
                    }
            ]
        }
    },
    methods:{
        save() {
           console.log( this.formTable,' this.formTable');
        }
    }
}
</script>
<style scoped lang="scss">
:deep(.el-select .el-input__wrapper.is-focus) {
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset !important;
}

:deep(.el-select .el-input.is-focus .el-input__wrapper) {
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset !important;
}

:deep(.el-input__wrapper) {
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset !important;
    border: none;

    .el-input__inner {}
}

:deep(.el-textarea__inner) {
    box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset;
    resize: none;
}

// :deep(.el-select-dropdown){
// 	box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset;
// 	border: none;
// }
// :deep(.el-select) {
// 	box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset;
//     border: none;
// }
// :deep(.el-input__inner){
//     border: none;
//     box-shadow: none;
// }  
:deep(.el-upload) {
    width: 3.14rem !important;
    height: 3.83rem !important;
}

:deep(.el-select) {
    margin-bottom: 0.2rem;
    //     -webkit-box-shadow:0px 3px 3px #c8c8c8 ;

    // -moz-box-shadow:0px 3px 3px #c8c8c8 ;

    // box-shadow:0px 3px 3px #c8c8c8 ;

}

.payment :deep(.el-input__wrapper) {
    background-color: #F5F7FA;
    -webkit-box-shadow: 0px 0.06rem 3px #c8c8c8;

    -moz-box-shadow: 0px 0.06rem 3px #c8c8c8;

    box-shadow: 0px 0.06rem 3px #c8c8c8;

}

:deep(.el-button) {
    width: 1.71rem;
    height: 0.45rem;
    background-color: #436E8A;
    color: white;
    font-size: 0.24rem;
}

.avatar-uploader {
    border: 1px dashed #dcdfe6;
}

.avatar-uploader:hover {
    border-color: 1px dashed #409eff;
}

.avatar-uploader .el-upload:hover {
    border-color: 1px dashed #409eff;
}

.avatar-uploader .el-upload {
    border: 1px dashed #dcdfe6;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}
@import './index.scss';
</style>